import React, {ReactNode} from 'react'
import Typography from '../../styles/Typography'
import GlobalStyle from '../../styles/GlobalStyle'

const Layout: React.FC<ReactNode> = ({children}) => (
  <>
    <Typography />
    <GlobalStyle />
    {children}
  </>
)

export default Layout
